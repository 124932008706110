import {
  Box,
  Text,
} from 'theme-ui';
import Link from 'next/link';
import { Image } from 'react-datocms';
import { useMediaQuery } from 'react-responsive';

export default function ProductList({ x, listItem }) {

  const handleProductOsobine = osobine => {
    return osobine.map(x => (
      <Box
        key={x.ime}
        sx={{
          position: 'relative',
          borderRadius: '50%',
          width: [32, 32, 64],
          height: [32, 32, 64],
          '&:hover span': {
            display: 'inline',
          },
        }}>
        <Box as="span">
          <Image data={x.ikonica.responsiveImage} />
        </Box>
        <Box
          as="span"
          sx={{
            color: 'text',
            fontSize: 2,
            textAlign: 'center',
            position: 'absolute',
            bg: 'white',
            p: 2,
            zIndex: 2,
            width: '100px',
            lineHeight: 1.3,
            bottom: '100%',
            left: '0',
            boxShadow: '0 1px 10px -5px',
            display: 'none',
          }}>
          {x.ime}
        </Box>
      </Box>
    ));
  };

  const handleFetchedPrice = () => {
    // If its a rinfuz product (define price for 1kg)
    if (x.rinfuz && x.prices && x.prices.amount_cents) {
      const rinfuzCena = `${((Math.round(x.prices.amount_cents) * 1000) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} RSD`;
      if (
        (x.prices && x.prices.amount_cents) !==
        (x.prices && x.prices.compare_at_amount_cents)
      ) {
        const originalnaCena = `${((Math.round(x.prices.compare_at_) * 1000) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} RSD`;
        return (
          <span>
            {' '}
            {rinfuzCena}{' '}
            <Text sx={{ textDecoration: 'line-through' }}>
              {originalnaCena}
            </Text>
          </span>
        );
      }
      return rinfuzCena;
    } else if (x.rinfuz && x.prices && x.prices.length > 0 && x.prices[0].amount_cents) {
      const rinfuzCena = `${((Math.round(x.prices[0].amount_cents) * 1000) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} RSD`;
      if (
        (x.prices && x.prices[0].amount_cents) !==
        (x.prices && x.prices[0].compare_at_amount_cents)
      ) {
        const originalnaCena = `${((Math.round(x.prices[0].compare_at_) * 1000) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} RSD`;
        return (
          <span>
            {' '}
            {rinfuzCena}{' '}
            <Text sx={{ textDecoration: 'line-through' }}>
              {originalnaCena}
            </Text>
          </span>
        );
      }
      return rinfuzCena;
    }

    // If its a packaged product
    if (x.prices && x.prices.amount_cents) {
      const pakovanoCena = `${(
        Math.round(x.prices && x.prices.amount_cents) / 100
      )
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} RSD`;
      if (
        (x.prices && x.prices.amount_cents) !==
        (x.prices && x.prices.compare_at_amount_cents)
      ) {
        const originalnaCena = `${(
          Math.round(x.prices && x.prices.compare_at_amount_cents) /
          100
        )
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} RSD`;
        return (
          <Box sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
          }}>
            <Text
              as="span"
              sx={{
                mb: 1,
                fontSize: 4,
              }}>
              {pakovanoCena}

            </Text>
            <Box>
              <Text
                as="span"
                sx={{
                  textDecoration: 'line-through',
                  fontSize: 2,
                  color: 'text',
                  ml: 3,
                  opacity: 0.9,
                }}>
                {originalnaCena}
              </Text>
            </Box>
          </Box>
        );
      }
      return pakovanoCena;
    } else if (x.prices && x.prices.length > 0 && x.prices[0].amount_cents) {
      const pakovanoCena = `${(
        Math.round(x.prices && x.prices[0].amount_cents) / 100
      )
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} RSD`;
      if (
        (x.prices && x.prices[0].amount_cents) !==
        (x.prices && x.prices[0].compare_at_amount_cents)
      ) {
        const originalnaCena = `${(
          Math.round(x.prices && x.prices[0].compare_at_amount_cents) /
          100
        )
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} RSD`;
        return (
          <Box sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
          }}>
            <Text
              as="span"
              sx={{
                mb: 1,
                fontSize: 4,
              }}>
              {pakovanoCena}

            </Text>
            <Box>
              <Text
                as="span"
                sx={{
                  textDecoration: 'line-through',
                  fontSize: 2,
                  color: 'text',
                  ml: 3,
                  opacity: 0.9,
                }}>
                {originalnaCena}
              </Text>
            </Box>
          </Box>
        );
      }
      return pakovanoCena;
    }
    return null
  };

  let productIsOnSales = false;
  if (x.prices && x.prices.amount_cents && x.prices.compare_at_amount_cents) {
    productIsOnSales = (x.prices && x.prices.amount_cents) !== (x.prices && x.prices.compare_at_amount_cents)
  } else if (x.prices && x.prices.length > 0 && x.prices[0].amount_cents && x.prices[0].compare_at_amount_cents) {
    productIsOnSales = (x.prices && x.prices[0].amount_cents) !== (x.prices && x.prices[0].compare_at_amount_cents)
  }

  const handleProductContainerWidth = number => {
    if (number === 8) return ['50%', '25%'];
    if (number === 2) return ['25%'];
    if (number === 3) return ['33.3%'];
  };

  let productSlug = ""
  if (x.breadcrumbsPaths.length > 0) {
    x.breadcrumbsPaths[0].pathOfCategories.forEach(cat => productSlug = productSlug + "/" + cat.slug)
  }

  const isMobile = useMediaQuery({ maxWidth: 980 });

  return (
    <Box
      as={listItem == false ? "div" : "li"}
      key={x.sku}
      className="item"
      sx={{
        width: ['100%'],
        px: [1, 3],
        py: 1,
        mt: 3,
        listStyle: 'none',
        ml: 0,
        pl: 0,
      }}>
      <Box
        sx={{
          overflow: 'hidden',
          pb: 20,
        }}>
        <Box sx={{ cursor: 'pointer' }}>
          <Link
            sx={{
              textDecoration: 'none',
              color: 'inherit',
            }}
            passHref
            href={`/proizvod/${x.slug}`}>
            <Box
              sx={{
                bg: '#fff',
                borderRadius: 'sm',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}>
              <Box
                px={[0, 0, 0, 0, 5]}
                pt={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: [100, 'auto', 320],
                  alignItems: 'center',
                  position: 'relative',
                }}>
                {productIsOnSales && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: [0, 0, 20],
                      left: 0,
                      width: [60, 60, 80],
                      height: [60, 60, 80],
                      borderRadius: 50,
                      bg: 'red',
                      zIndex: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Text
                      sx={{
                        fontSize: '3',
                        fontFamily: 'heading',
                        fontWeight: '500',
                        color: 'white',
                      }}>
                      akcija
                    </Text>
                  </Box>
                )}
                <Box sx={{
                  paddingBottom: '0px!important',
                  height: [200, 200, '320px'],
                  // bg: 'blue',
                  // height: '100%',
                  width: '100%',
                  // position: 'absolute',
                }}>
                  {x && x.slika && <Image height="100%" width="100%" layout="fill" objectFit="contain" data={x.slika.responsiveImage} />}

                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    color: 'white',
                    bottom: 0,
                    left: [0, 0, 0, 4],
                    zIndex: 2,
                  }}>
                  <Box as="ul" m={0} p={0}>
                    {handleProductOsobine(x.osobine)}
                  </Box>
                </Box>
              </Box>

              <Box px={[2, 2, 2, 4]} mt={2} pb={3} pt={2}>
                <Text
                  sx={{
                    fontSize: '1',
                    textTransform: 'uppercase',
                    fontFamily: 'body',
                    letterSpacing: '0.5px',
                    color: 'darkGray',
                  }}>
                  {x.robnaMarka && x.robnaMarka.imeRobneMarke}
                </Text>
                <Box>
                  <Text
                    sx={{
                      fontSize: ['18px', '16px', '18px', 24],
                      fontFamily: 'heading',
                      lineHeight: '1.3',
                      fontWeight: 500,
                      color: 'text',
                    }}>
                    {x.name}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            color: 'orange',
            pl: [2, 2, 24],
          }}>

          <Text
            sx={{
              // fontSize: (prices && prices.amountFloat ? [5, 6] : [4, 5]),
              fontFamily: 'body',
              fontSize: 3,
              mr: '1',
              fontWeight: 500,
            }}>
            {/* {categoryPrices && handleFetchedPrice(categoryPrices)} */}
            {handleFetchedPrice()}
            {/* {productData.prices && productData.prices.amountFloat ? productData.prices.amountFloat : 'Nije u ponudiaa' } */}
          </Text>
        </Box>
        {(x?.stock_items?.quantity == 0) || (x?.stock_items && x?.stock_items[0]?.quantity == 0) && <Box>
          Trenutno nema na stanju
        </Box>}
      </Box>
    </Box>
  );
};