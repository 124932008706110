
import {
  Box
} from "theme-ui"
// import dynamic from 'next/dynamic';
import ProductList from '@/components/handleProductListing'
// const ProductList = dynamic(() => import('@/components/handleProductListing'), {
//   suspense: true,
// })

import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'

// import { Suspense } from 'react';

export default function ProductCarousel({
  array = [],
  sliderClass,
}) {
  const [refCallback, slider, sliderNode] = useKeenSlider(
    {
      // loop: true,
      slides: {
        perView: 2,
      },
      breakpoints: {
        '(min-width: 500px)': {
          slides: {
            perView: 3,
          },
        },
        '(min-width: 800px)': {
          slides: {
            perView: 4,
          },
        },
      },
      slideChanged() {
        console.log('slide changed')
      },
    },
    [
      // add plugins here
    ]
  )
  

  return (
    <Box ref={refCallback} className="keen-slider" sx={{ px: 3, }}>
      {array.map((x, i) => {
        if (i > 9) return
        return (
          <div className="keen-slider__slide" key={`keenslider${i}`}>
              <ProductList listItem={false} x={x}/>
          </div>
        )
      }
      )}
    </Box >
  )
}